import React from 'react';

function buttons(tool, item, index, callback) {
  if (tool === "moderation") {
    return (
      <div className="buttons">
        <button onClick={()=>callback(item, true, index, tool)} className="button approve">Approve</button>
        <button onClick={()=>callback(item, false, index, tool)} className="button reject">Reject</button>
      </div>
    )
  } else {
    //Homepage
    if (item.homepage) {
      return (
        <button onClick={()=>callback(item, false, index, tool)} className="button reject">Remove from Homepage</button>
      )
    } else {
      return (
        <button onClick={()=>callback(item, true, index, tool)} className="button approve">Add to Homepage</button>
      )
    }
  }
}

function youTubeThumbnail(link) {
  var validLinkPatterns = [
    "https://youtu.be/",
    "https://www.youtube.com/watch?v="
  ];

  var source = null;

  //special check for embed code being used:
  if (link.includes("https://www.youtube.com/embed/")) {
    var regex = new RegExp(`src="https://www.youtube.com/embed/(.*?)"`, "gs");
    var sourceRegex = regex.exec(link);
    source = sourceRegex[1];
  }

  //link format checks
  validLinkPatterns.forEach((item, index) => {
    if (link.includes(item)) {
      source = link.replace(item,"");
    }
  });

  return "http://img.youtube.com/vi/"+source+"/0.jpg";
}

export default function MediaItem(props) {

  var displayType = "image";

  if (props.item.contentType) {
    var contentType = props.item.contentType.split("/");
    displayType = contentType[0];
  }

  switch (displayType) {
    case "image":
      return (
        <div className="mediaItem">
          <div className="half">
            <img className="moderationImage" alt={props.item.firestoreId} src={props.item.fileURL}/>
          </div>
          <div className="half">
            { buttons(props.tool, props.item, props.index, props.callback) }
          </div>
        </div>
      )
    case "video":
      return (
        <div className="mediaItem">
          <div className="half">
            <a href={props.item.fileURL} alt={props.item.firestoreId} rel="noopener noreferrer" target="_blank">
              <img className="moderationImage" alt={props.item.firestoreId} src={youTubeThumbnail(props.item.fileURL)}/>
            </a>
          </div>
          <div className="half">
            { buttons(props.tool, props.item, props.index, props.callback) }
          </div>
        </div>
      )
    default: return false;
  }

}
