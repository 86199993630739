import React from 'react';
import spinner from '../spinner.gif';

function DeleteUser(props) {

  const [loading, setLoading] = React.useState(true);
  const [userList, setUserList] = React.useState(null);
  const [filteredUserList, setFilteredUserList] = React.useState(null);
  const [itemStatus, setItemStatus] = React.useState({});
  const [messagingStatus, setMessagingStatus] = React.useState({});
  const [userSearch, setUserSearch] = React.useState('');
  const [agentNameSearch, setAgentNameSearch] = React.useState('');
  const mounted = React.useRef(false);

  const loadUsers = () => {
    var usersState = [];

    var users = props.db.collectionGroup('users');
    users = users.orderBy("username", "asc");
    users.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        var item = doc.data()
        item = {
          ...item,
          firestoreId: doc.id,
        }

        usersState.push(item);
      });
      mounted.current && setLoading(false);
      mounted.current && setUserList(usersState);
      mounted.current && setFilteredUserList(usersState);
    });
  }

  React.useEffect(()=>{
    mounted.current = true;
    loadUsers();
    return ()=>{
      mounted.current = false;
    }
  },[]);

  const filter = (value, name) => {
    if (!name) {
      return;
    }
    if (userList?.length < 1) {
      loadUsers();
    }
    var filteredUsers = [];
    const re = new RegExp(value, "i");
    userList.forEach((user, i) => {
      var result;
      switch (name) {
        case "userSearch":
          result = re.test(user?.username);//re.exec(user?.username.toLowerCase());
          break;
        case "agentNameSearch":
          result = re.text(user?.agentName); //re.exec(user?.agentName?.toLowerCase());
          break;
        default:
          break;
      }
      if (result) {
        filteredUsers.push(user);
      }
    });
    mounted.current && setFilteredUserList(filteredUsers);
  }

  const handleChange = (evt) => {
    switch (evt?.target?.name) {
      case "userSearch":
        mounted.current && setUserSearch(evt.target.value);
        break;
      case "agentNameSearch":
        mounted.current && setAgentNameSearch(evt.target.value);
        break;
      default:
        return;
    }
    filter(evt.target.value, evt?.target?.name);
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
  }

  const Search = () => {
    return (
      <>
      <h3>Search</h3>
      <form className="searchForm" onSubmit={handleSubmit}>
        <div className="search">
          <div>
            <label>
              Username&nbsp;
              <input
                type="text"
                value={userSearch}
                name="userSearch"
                onChange={handleChange}
              />
            </label>
          </div>
          <div>
            <label>
              Agent Name&nbsp;
              <input
                type="text"
                value={agentNameSearch}
                name="agentNameSearch"
                onChange={handleChange}
              />
            </label>
          </div>
        </div>
      </form>
      </>
    )
  }

  const callback = async (item, index, action) => {

    if (!item || !index || !action) {
      if (index !== 0) {
        return false;
      }
    }

    if (props.confirm) {
      var selection = window.confirm("Are you sure you want to "+ action +" the user " + item.username +"?");
      if (selection === false) {
        return false;
      }
    }

    var itemRef = props.db.collection('users').doc(item.firestoreId);

    var value;

    if (action === "disable messaging for" || action === "enable messaging for") {
      value = checkValue(item, index, "messaging");
      try {
        itemRef.update({
          messagingDisabled: !value
        }).then((result)=>{
          console.log('Success', {...messagingStatus, ...{[item.firestoreId]: value ? 'enabled' : 'disabled'}});
          mounted.current && setMessagingStatus({...messagingStatus, ...{[item.firestoreId]: value ? 'enabled' : 'disabled'}});
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (action === "disable" || action === "enable") {
      value = checkValue(item, index, "disabled");
      try {
        itemRef.update({
          userDisabled: !value
        }).then((result)=>{
          console.log('Success', {...itemStatus, ...{[item.firestoreId]: value ? 'enabled' : 'disabled'}})
          mounted.current && setItemStatus({...itemStatus, ...{[item.firestoreId]: value ? 'enabled' : 'disabled'}});
        });
      } catch (error) {
        console.error(error);
      }
    }

    if (action === "delete") {
      try {
        itemRef.delete().then((result)=>{
          console.log('Success', {...itemStatus, ...{[item.firestoreId]: 'deleted'}});
          mounted.current && setItemStatus({...itemStatus, ...{[item.firestoreId]: 'deleted'}});
        });
      } catch (error) {
        console.error(error);
      }
    }
  }

  const checkValue = (item, index, valueName) => {
    var value = false;

    if (valueName === "messaging") {
      if (messagingStatus[item.firestoreId]) {
        if (messagingStatus[item.firestoreId] === "disabled") {
          value = true;
        } else {
          value = false
        }
      } else {
        value = item.messagingDisabled;
      }
    }

    if (valueName === "disabled") {
      if (itemStatus[item.firestoreId]) {
        if (itemStatus[item.firestoreId] === "disabled") {
          value = true;
        } else {
          value = false
        }
      } else {
        value = item.userDisabled;
      }
    }

    return value;
  }

  const Buttons = ({item, index}) => {
    return (
      <div className="buttons">
        <button onClick={()=>callback(item, index, checkValue(item, index, "messaging") ? 'enable messaging for' : 'disable messaging for')} className="button messaging">{checkValue(item, index, "messaging") ? 'Enable' : 'Disable'} Messaging</button>
        <button onClick={()=>callback(item, index, checkValue(item, index, "disabled") ? 'enable' : 'disable')} className="button approve">{checkValue(item, index, "disabled") ? 'Enable' : 'Disable'} User</button>
        <button onClick={()=>callback(item, index, 'delete')} className="button reject">Delete User</button>
      </div>
    )
  }

  if (loading) {
    return (
      <div className="App">
        <div className="spinner">
          <img src={spinner} alt="Loading..."/>
        </div>
      </div>
    )
  }

  return (
    <>
    {Search()}
    {(filteredUserList?.length > 0) ?
      filteredUserList.map((item, index) => {
        if (itemStatus[item.firestoreId] === 'deleted') {
          return null;
        }
        return (
          <div key={index.toString()}>
            <b>{item.username}{(item.userDisabled || (itemStatus[item.firestoreId] === 'disabled')) && ' - account disabled'}</b><br/>
              {item.email}{(item.messagingDisabled || (messagingStatus[item.firestoreId] === 'disabled')) && ' - messaging disabled'}
            <div className="mediaItem">
              <div className="half">
                <a target="_blank" rel="noopener noreferrer" href={`https://rawrevolt.com/${item.type.toLowerCase()}/${item.username}`}>
                  <img className="profileImage" alt={item.firestoreId} src={item.profileImage || 'https://firebasestorage.googleapis.com/v0/b/raw-revolt.appspot.com/o/defaultProfileImage.png?alt=media&amp;token=0e4be1f0-182a-457f-a3dc-cae4e811e9c5'}/>
                </a>
              </div>
              <div className="half">
                <p><b>Agent:</b> {item.agentName || 'None'}</p>
                <Buttons item={item} index={index}/>
              </div>
            </div>
          </div>
        );
      })
      :
      <p style={{textAlign:'center'}}>There are no users to moderate currently</p>
    }
    </>
  );
}

export default React.memo(DeleteUser);
