import '../config/styles.css';
import React from 'react';
import {firebaseConfig} from '../config/firebaseConfig'
import DeleteUser from './deleteUser';
import MediaItem from '../components/mediaItem'
import spinner from '../spinner.gif';

require("firebase/auth");
require("firebase/firestore");
const firebase = require("firebase");

class Home extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      media: [],
      confirm: true,
      currentTool: 'moderation',
      loading: false,
      auth: false,
      showDelete: false,//true,
      password: null
    }

    if (firebase.apps.length === 0) {
      firebase.initializeApp(firebaseConfig);
    }

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    this.storage = firebase.storage();
  }

  fetchMedia = (approved = false) => {
    this.setState({showDelete: false, loading: true}, () => {
      if (approved===false) {
        this.setState({currentTool: "moderation"});
      } else {
        this.setState({currentTool: "homepage"});
      }

      var mediaState = [];

      var media = this.db.collectionGroup('media');
      media = media.where("approved", "==", approved);
      media.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          var item = doc.data()
          item = {
            ...item,
            firestoreId: doc.id,
            userId: doc.ref.parent.parent.id
          }

          doc.ref.parent.parent.get().then((doc) => {
            item = {
              ...item,
              username: doc.data().username,
              isProfileImage: doc.data().profileImageId === item.firestoreId ? true : false
            }
            mediaState.push(item);
            this.setState({media: mediaState });
          });
        });
        this.setState({loading:false});
      });
    });
  }

  takeAction = (item = null, choice = null, index = null, tool = null) => {
    if (tool === "moderation") {
      this.moderate(item, choice, index);
    } else {
      this.homepage(item, choice, index);
    }
  }

  homepage = (item = null, choice = true, index = null) => {
    if (item) {
      var itemRef = this.db.collection('users').doc(item.userId).collection('media').doc(item.firestoreId);
      if (this.state.confirm) {
        var action = choice ? 'add this item to' : 'remove this item from';
        var selection = window.confirm("Are you sure you want to "+ action +" the homepage?");
        if (selection === false) {
          return false;
        }
      }
      if (choice) {
        itemRef.update({
          homepage: true
        }).then(() => {
          console.log("Successfully added to homepage");
          //refresh the display without reading from DB again
          var newState = this.state.media
          newState[index].homepage = true;
          this.setState({media: newState});
        })
        .catch((error) => {
          // The document probably doesn't exist.
          alert("Error updating document: ", error);
        });
      } else {
        itemRef.update({
          homepage: false
        }).then(() => {
          console.log("Successfully removed from homepage");
          //refresh the display without reading from DB again
          var newState = this.state.media
          newState[index].homepage = false;
          this.setState({media: newState});
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          alert("Error removing document: ", error);
        });
      }
    }
  }

  moderate = (item = null, approved = true, index = null) => {
    //add way of instantly refreshing the UI
    if (item) {
      console.log(item)
      if (this.state.confirm) {
        var action = approved ? 'approve' : 'reject';
        var choice = window.confirm("Are you sure you want to "+ action +" this item?");
        if (choice === false) {
          return false;
        }
      }
      var itemRef = this.db.collection('users').doc(item.userId).collection('media').doc(item.firestoreId);
      if (approved) {
        itemRef.update({
          approved: true,
          homepage: false
        }).then(() => {
          console.log("Successfully approved");
          if (item.isProfileImage) {
            itemRef.parent.parent.update({
              profileImageApproved: true
            });
          }
          //refresh the display without reading from DB again
          var newState = this.state.media
          newState.splice(index, 1);
          this.setState({media: newState});
        })
        .catch((error) => {
          // The document probably doesn't exist.
          alert("Error updating document: ", error);
        });
      } else {
        if (item.fullPath) {
          this.storage.ref().child(item.fullPath).delete().then(() => {
            // File deleted successfully
            console.log("Successful deletion of actual file");
          }).catch((error) => {
            // Uh-oh, an error occurred!
            alert("Error deleting file");
          });
        }
        itemRef.delete().then(() => {
          console.log("Successful deletion of firestore document");
          if (item.isProfileImage) {
            itemRef.parent.parent.update({
              profileImage: "https://firebasestorage.googleapis.com/v0/b/raw-revolt.appspot.com/o/defaultProfileImage.png?alt=media&token=0e4be1f0-182a-457f-a3dc-cae4e811e9c5",
              profileImageId: null
            });
          }
          //refresh the display without reading from DB again
          var newState = this.state.media
          newState.splice(index, 1);
          this.setState({media: newState});
        })
        .catch(function(error) {
          // The document probably doesn't exist.
          alert("Error removing document: ", error);
        });
      }
    }
  }

  authenticate = async () => {
    if (this.state.password) {
      var errorCode;
      var errorMessage;
      var loginStatus=true;
      await this.auth.signInWithEmailAndPassword("tech@rawrevolt.com", this.state.password).catch((error) => {
        // Handle Errors here.
        console.log("Ran sign in and failed");
        errorCode = error.code;
        errorMessage = error.message;

        console.log(errorCode);
        console.log(errorMessage);

        loginStatus=false;
      });
      if (loginStatus) {
        this.setState({auth: true, password: null});
        this.fetchMedia(false);
      } else {
        alert(errorMessage);
      }
    }
  }

  render() {
    if (!this.state.auth) {
      return (
        <div className="App">
        <header>
          <div className="title">
            <h1>Log In</h1>
          </div>
        </header>
          <form onSubmit={(e)=>{e.preventDefault(); this.authenticate()}}>
            <input className="input" type="password" onChange={(e) => {this.setState({password: e.target.value})}}/>
            <button type="submit" className="button">Log In</button>
          </form>
        </div>
      )
    } else {
      return (
        <div className="App">
          <header>
            <div className="title">
              <h1>Raw Revolt Admin</h1>
              <div className="tools">
                <button onClick={()=>this.fetchMedia(false)} className="button">Media moderation</button>
                <button onClick={()=>this.setState({showDelete: true})} className="button">User management</button>
                <button onClick={()=>this.setState({auth: false})} className="button">Log Out</button>
              </div>
            </div>
            <div className="actions">
              <div className="right">
                <input type="checkbox" id="confirm" name="confirm" checked={this.state.confirm} onChange={() => this.setState({confirm: !this.state.confirm})} />
                <label htmlFor="confirm">Confirm choices?</label><br/>
              </div>
            </div>
          </header>
          {this.state.showDelete ?
            <DeleteUser db={this.db} confirm={this.state.confirm}/>
            :
            <>
            { this.state.loading ?
              <div className="App">
                <div className="spinner">
                  <img src={spinner} alt="Loading..."/>
                </div>
              </div>
            :
            <main>
            {(this.state.media.length > 0) ?
              this.state.media.map((item, index) => {
                return <MediaItem key={item.firestoreId} item={item} index={index} tool={this.state.currentTool} callback={(item, choice, index, tool) => this.takeAction(item, choice, index, tool)} />;
              })
              :
              <p style={{textAlign:'center'}}>There are no media items to moderate currently</p>
            }
            </main>
            }
            </>
          }
        </div>
      );
    }
  }

}

export default Home;
